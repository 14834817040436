




































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";

import "vue-select/dist/vue-select.css";
import _ from "lodash";
Object.defineProperty(Vue.prototype, "$_", { value: _ });
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "venue-app-features-default" as string,

  components: {
    Layout,
  },

  data() {
    return {
      title: "Songoroo app features",
    };
  },
  async created(): Promise<void> {
    await this.checkLoggedUserRolePermission({ permissionId: 4 });
  },
  computed: {
    ...mapGetters("companyModule", {
      company: "GET_COMPANY",
    }),
    ...mapGetters("userModule", {
      hasCreateDeletePermission: "GET_CREATE_DELETE_VENUE_PERMISSION",
    }),
  },
  methods: {
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION",
    }),
    goToAddNewVenue() {
      this.$router.push("/add-new-venue-step-1");
    },
  },
});
